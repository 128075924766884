import React, { Fragment, useState, useEffect, useContext } from "react"
import MetaTags from "../../src/MetaTags"
import { useRouter } from "next/router"
import { useTranslation } from "next-i18next"
import { serverSideTranslations } from "next-i18next/serverSideTranslations"
import ProductList from "../../src/ProductList"
import LayoutFirebase from "../../src/LayoutFirebase"
import { FirebaseContext } from "../../src/Firebase"
import Loading from "../../src/Loading"
import useRedirectToProfile from "../../src/useRedirectToProfile"
import LoadingButton, { Button } from "../../src/LoadingButton"
import {
  Bed,
  Couch,
  Utensils,
  Bath,
  Chair,
  Home,
  BabyCarriage,
} from "@styled-icons/fa-solid"
import { Dialog, Transition } from "@headlessui/react";
import Countdown from 'react-countdown'
import { UserImages } from "../../src/AI-Generations"
import { EditArrowBack } from '@styled-icons/fluentui-system-regular/EditArrowBack'
import { Library } from '@styled-icons/fluentui-system-filled/Library'
import { Theaters as Theater } from '@styled-icons/material/Theaters'
import { 
  LocalLaundryService, 
  MusicNote, 
  Brush, 
  SportsEsports, 
  FitnessCenter, 
  Kitchen, 
  Storage, 
  Weekend,
  Toys
} from '@styled-icons/material';
import { Plant } from '@styled-icons/remix-fill/Plant'

import { 
  WineBottle, 
  Book 
} from '@styled-icons/fa-solid';
import Head from 'next/head'
import { aiGenerations } from "../../src/FirebaseApp"
import { BrushableImage } from "../../src/BrushableImage"
import { ImageUpload } from "../../src/ImageUpload"

import VersionSelectionModal from "../../src/VersionSelectionModal";

const styles = [
  "Contemporary",
  "Modern",
  "Traditional",
  "Rustic",
  "Minimalist",
  "Industrial",
  "Coastal",
  "Bohemian",
  "Scandinavian",
  "Mid-Century Modern",
  "Art Deco",
  "French Country",
  "Shabby Chic",
  "Hollywood Regency",
  "Tropical",
  "Farmhouse",
  "Craftsman",
  "Gothic",
  "Asian Zen",
  "Southwestern",
  "Victorian",
  "Transitional",
  "Mediterranean",
  "Moroccan",
  "Eclectic",
  "Nautical"
];

const ICON_SIZE = 25


const roomTypes = [
  {
    name: "Living Room",
    icon: <Couch size={ICON_SIZE} />,
    defaultItems: "artwork, sofa, armchair, storage cabinet",
    url: "living-room",
    title: '🛋️ AI-Generated Living Room Mood Boards | Artwork, Sofa, Armchair, Storage Cabinet Inspirations',
    description: 'Use AI to generate creative mood boards for your living room. Get inspiration for your artwork, sofas, armchairs, and storage cabinets. Experience the future of interior design.',
    heading: 'Generate Your Own Living Room Mood Boards with AI',
  },
  {
    name: "Dining Room",
    icon: <Chair size={ICON_SIZE} />,
    defaultItems: "dining table, chairs, cabinet",
    url: "dining-room",
    title: '🪑 AI-Generated Dining Room Mood Boards | Dining Table, Chairs, Cabinet Inspirations',
    description: 'Create unique mood boards for your dining room with our AI. Discover new arrangements for dining tables, chairs, and cabinets. Unleash your interior design potential.',
    heading: 'Craft Your Dining Room Style with AI Mood Boards',
  },
  {
    name: "Bedroom",
    icon: <Bed size={ICON_SIZE} />,
    defaultItems: "bed, wardrobe, nightstand",
    url: "bedroom",
    title: '🛏️ AI-Generated Bedroom Mood Boards | Bed, Wardrobe, Nightstand Inspirations',
    description: 'Generate AI-powered mood boards for your bedroom. Find perfect matches for your bed, wardrobe, and nightstand. Step into the future of interior design.',
    heading: 'Design Your Dream Bedroom with AI Mood Boards',
  },
  {
    name: "Kitchen",
    icon: <Utensils size={ICON_SIZE} />,
    defaultItems: "stove, refrigerator, sink",
    url: "kitchen",
    title: '🍴 AI-Generated Kitchen Mood Boards | Stove, Refrigerator, Sink Inspirations',
    description: 'Use AI to create inspirational mood boards for your kitchen. Explore ideas for your stove, refrigerator, and sink arrangements. Discover the future of kitchen design.',
    heading: 'Compose Your Kitchen Themes with AI Mood Boards',
  },
  {
    name: "Bathroom",
    icon: <Bath size={ICON_SIZE} />,
    defaultItems: "bathtub, sink, toilet, cabinet",
    url: "bathroom",
    title: "Design Tranquil Bathroom Mood Boards",
    heading: "Create your Tranquil Bathroom with AI",
    description: "Design your ideal bathroom with AI-generated mood boards. Discover the perfect balance of bathtubs, sinks, toilets, and cabinets in your bathroom design.",
},
{
    name: "Home Office",
    icon: <Home size={ICON_SIZE} />,
    defaultItems: "desk, office chair, bookshelf",
    url: "home-office",
    title: "Develop Productive Home Office Mood Boards",
    heading: "Create your Ideal Home Office with AI",
    description: "Design a productive home office with AI-generated mood boards. Get inspired by setups with desks, office chairs, and bookshelves.",
},
{
    name: "Nursery",
    icon: <BabyCarriage size={ICON_SIZE} />,
    defaultItems: "crib, changing table, dresser",
    url: "nursery",
    title: "Design Loving Nursery Mood Boards",
    heading: "Design your Loving Nursery with AI",
    description: "Create a loving nursery with AI-generated mood boards. Discover a world of cute and practical designs featuring cribs, changing tables, and dressers.",
},
{
  name: "Kids Room",
  icon: <Toys size={30} />, 
  defaultItems: "bunk bed, toy storage, study desk",
  url: "kids-room",
  title: '🧸 AI-Generated Kids Room Mood Boards | Bunk Bed, Toy Storage, Study Desk Inspirations',
  description: 'Generate imaginative and playful mood boards for your kids room with our AI. Get creative ideas for bunk beds, toy storage, and study desks. Transform your child’s space into a fun and functional area.',
  heading: 'Create Magical Kids Room Spaces with AI Mood Boards',
},
  {
    name: "Yoga Studio",
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7 inline-block"
       viewBox="0 0 512 512"><g xmlns="http://www.w3.org/2000/svg">
       <path fill="currentColor" d="M482.752,435.574c-6.928-8.1-23.127-40.492-23.127-40.492s2.676-3.448,0-15.051   c-3.48-15.035-18.514-13.886-21.978-17.349c-3.479-3.472-33.549-58.424-35.863-64.792c-2.314-6.369-27.772-78.662-27.772-78.662   c-8.549-37.604-24.308-53.221-45.121-57.85c-20.64-4.581-31.817-3.471-41.075-11.571c-5.778-5.054-5.573-8.809-5.573-24.056   c0,0,6.235-5.927,10.784-14.122c5.195-9.375,7.746-22.907,7.746-22.907c5.211-2.086,5.274-4.684,7.525-12.965   c3.118-11.461,2.897-19.317-5.431-19.317C304.836,19.066,286.085,0,256,0c-30.07,0-48.821,19.066-46.853,56.441   c-8.328,0-8.564,7.856-5.432,19.317c2.251,8.281,2.314,10.879,7.51,12.965c0,0,2.55,13.532,7.762,22.907   c4.55,8.194,10.784,14.122,10.784,14.122c0,15.247,0.189,19.002-5.589,24.056c-9.242,8.1-20.435,6.99-41.059,11.571   c-20.828,4.628-36.572,20.246-45.12,57.85c0,0-25.457,72.294-27.771,78.662c-2.314,6.368-32.401,61.32-35.864,64.792   c-3.464,3.463-18.514,2.314-21.978,17.349c-2.676,11.603,0,15.051,0,15.051s-16.2,32.392-23.143,40.492   c-6.942,8.092,5.794,13.878,13.886,3.464c0.944,1.409,4.156,2.424,7.793,2.912c-28.228,31.251-12.138,71.964,31.55,69.98   C118.291,510.3,256,485.316,256,485.316S393.707,510.3,429.54,511.93c43.688,1.984,59.778-38.729,31.534-69.98   c3.652-0.488,6.864-1.503,7.808-2.912C476.974,449.452,489.695,443.666,482.752,435.574z M183.123,383.849   c0,0-59.274,17.626-96.192,34.234c7.604-14.154,16.357-33.423,16.357-33.423l37.029-53.212l29.504-64.218   c0,0,9.257,34.714,12.138,39.917C184.855,312.35,183.123,383.849,183.123,383.849z M328.891,383.849c0,0-1.732-71.498,1.149-76.702   c2.897-5.203,12.154-39.917,12.154-39.917l29.504,64.218l37.013,53.212c0,0,8.769,19.27,16.373,33.423   C388.165,401.474,328.891,383.849,328.891,383.849z"/>
     </g>
</svg>
    ),
    defaultItems: "yoga mats, blocks, straps, meditation cushions, big windows",
    url: "yoga-studio",
    title: '🧘 AI-Generated Yoga Studio Mood Boards | Yoga Mats, Blocks, Straps, Meditation Cushions, Big Windows Inspirations',
    description: 'Design your dream yoga studio with AI-generated mood boards. Get inspiration for yoga mats, blocks, straps, meditation cushions, and big windows. Experience the zen of design.',
    heading: 'Design Your Dream Yoga Studio with AI Mood Boards',
  },
  {
    name: "Garden",
    icon: <Plant size={ICON_SIZE} />,
    defaultItems: "plants, outdoor furniture, garden tools",
    url: "garden",
    title: '🌳 AI-Generated Garden Mood Boards | Plants, Outdoor Furniture, Garden Tools Inspirations',
    description: 'Craft your perfect garden with AI-generated mood boards. Explore ideas for plants, outdoor furniture, and garden tools. Dive into the future of gardening.',
    heading: 'Design Your Dream Garden with AI Mood Boards',
  },
  {
    name: "Library",
    icon: <Library size={ICON_SIZE} />,
    defaultItems: "books, reading chair, desk, reading lamp",
    url: "library",
    title: '📚 AI-Generated Library Mood Boards | Books, Reading Chair, Desk, Reading Lamp Inspirations',
    description: 'Create a cozy library with AI-generated mood boards. Find ideas for your books, reading chair, desk, and reading lamp. Discover the future of reading room design.',
    heading: 'Design Your Perfect Library with AI Mood Boards',
  },
  {
    name: "Theater",
    icon: <Theater size={ICON_SIZE} />,
    defaultItems: "projector, comfortable chairs, sound system",
    url: "theater",
    title: '🎥 AI-Generated Home Theater Mood Boards | Projector, Comfortable Chairs, Sound System Inspirations',
    description: 'Design your home theater with AI-generated mood boards. Discover ideas for your projector, comfortable chairs, and sound system. Dive into the future of home cinema design.',
    heading: 'Design Your Home Theater with AI Mood Boards',
  },
  {
    name: "Studio",
    icon: <Brush size={ICON_SIZE} />,
    defaultItems: "easel, art supplies, good lighting, sink",
    url: "studio",
    title: "Create Amazing Studio Mood Boards",
    heading: "Generate your Dream Studio with AI",
    description: "Explore unlimited studio setup possibilities with our AI-powered mood board generator. Find the perfect harmony between easel, art supplies, good lighting, and sink.",
},
{
    name: "Game Room",
    icon: <SportsEsports size={ICON_SIZE} />,
    defaultItems: "pool table, dart board, board games, seating area",
    url: "game-room",
    title: "Innovative Game Room Mood Boards",
    heading: "Generate Exciting Game Room Ideas with AI",
    description: "Revolutionize your game room design with AI-generated mood boards. Discover captivating setups with pool tables, dart boards, board games, and comfortable seating areas.",
},
{
    name: "Wine Cellar",
    icon: <WineBottle size={ICON_SIZE} />,
    defaultItems: "wine racks, wine fridge, tasting table",
    url: "wine-cellar",
    title: "Curate Sophisticated Wine Cellar Mood Boards",
    heading: "Create your Elegant Wine Cellar with AI",
    description: "Design your ideal wine cellar using AI-generated mood boards. Uncover stylish concepts featuring wine racks, wine fridges, and tasting tables.",
},
{
    name: "Music Room",
    icon: <MusicNote size={ICON_SIZE} />,
    defaultItems: "piano, guitar stand, music sheets, music stands",
    url: "music-room",
    title: "Compose Harmonious Music Room Mood Boards",
    heading: "Generate your Ideal Music Room with AI",
    description: "Harmonize your music room design with AI-generated mood boards. Get inspired by ideas for pianos, guitar stands, music sheets, and music stands.",
},
{
    name: "Fitness Room",
    icon: <FitnessCenter size={ICON_SIZE} />,
    defaultItems: "treadmill, dumbbells, exercise bike, yoga mat",
    url: "fitness-room",
    title: "Craft Energizing Fitness Room Mood Boards",
    heading: "Create your Ultimate Fitness Room with AI",
    description: "Get your fitness room into shape with AI-generated mood boards. Discover layouts with treadmills, dumbbells, exercise bikes, and yoga mats.",
},
{
    name: "Pantry",
    icon: <Kitchen size={ICON_SIZE} />,
    defaultItems: "shelves, canned goods, dry goods",
    url: "pantry",
    title: "Develop Organized Pantry Mood Boards",
    heading: "Organize your Pantry with AI Inspiration",
    description: "Plan your perfect pantry with AI-generated mood boards. Get inspired by ideas for shelves, canned goods, and dry goods storage.",
},
{
    name: "Laundry Room",
    icon: <LocalLaundryService size={ICON_SIZE} />,
    defaultItems: "washer, dryer, laundry baskets, ironing board",
    url: "laundry-room",
    title: "Design Efficient Laundry Room Mood Boards",
    heading: "Plan your Laundry Room with AI",
    description: "Design your ideal laundry room with AI-generated mood boards. Uncover efficient setups with washers, dryers, laundry baskets, and ironing boards.",
},
{
    name: "Mudroom",
    icon: <Home size={ICON_SIZE} />,
    defaultItems: "coat rack, shoe storage, bench",
    url: "mudroom",
    title: "Design Practical Mudroom Mood Boards",
    heading: "Create your Functional Mudroom with AI",
    description: "Design a practical mudroom with AI-generated mood boards. Discover setups for coat racks, shoe storage, and benches.",
},
{
    name: "Balcony",
    icon: <Weekend size={ICON_SIZE} />, 
    defaultItems: "outdoor seating, plants, small table",
    url: "balcony",
    title: "Build Tranquil Balcony Mood Boards",
    heading: "Create your Peaceful Balcony with AI",
    description: "Plan your balcony escape with AI-generated mood boards. Explore ideas for outdoor seating, plants, and small tables.",
},
{
    name: "Attic",
    icon: <Storage size={ICON_SIZE} />,
    defaultItems: "storage boxes, old furniture",
    url: "attic",
    title: "Create Spacious Attic Mood Boards",
    heading: "Design your Spacious Attic with AI",
    description: "Reimagine your attic with AI-generated mood boards. Discover designs for storage boxes and old furniture setups.",
},
{
    name: "Basement",
    icon: <Storage size={ICON_SIZE} />,
    defaultItems: "storage boxes, unused appliances, old furniture",
    url: "basement",
    title: "Plan Functional Basement Mood Boards",
    heading: "Plan your Functional Basement with AI",
    description: "Plan your basement makeover with AI-generated mood boards. Explore functional designs for storage boxes, unused appliances, and old furniture.",
},
{
    name: "Closet",
    icon: <Storage size={ICON_SIZE} />,
    defaultItems: "clothes, shoes, accessories",
    url: "closet",
    title: "Craft Organized Closet Mood Boards",
    heading: "Design your Organized Closet with AI",
    description: "Organize your closet with AI-generated mood boards. Get inspired by ideas for clothes, shoes, and accessories storage.",
},
{
    name: "Library",
    icon: <Book size={ICON_SIZE} />,
    defaultItems: "books, reading chair, desk, reading lamp",
    url: "library",
    title: "Design Cozy Library Mood Boards",
    heading: "Design your Cozy Library with AI",
    description: "Design a cozy library with AI-generated mood boards. Get inspired by ideas for books, a reading chair, a desk, and a reading lamp.",
},
]



const colorCombinations = [
  {
    name: "Neutrals: White, Light Grey, Dark Grey",
    value: ["White", "Light Grey", "Dark Grey"],
  },
  {
    name: "Neutrals: White, Beige, Light Brown",
    value: ["White", "Beige", "Light Brown"],
  },
  { name: "Neutrals: White, Black, Red", value: ["White", "Black", "Red"] },
  {
    name: "Neutrals: White, Off-White, Navy Blue",
    value: ["White", "Off-White", "Navy Blue"],
  },
  {
    name: "Pastels: Light Pink, Light Blue, Light Green",
    value: ["Light Pink", "Light Blue", "Light Green"],
  },
  {
    name: "Pastels: Lavender, Light Pink, Light Grey",
    value: ["Lavender", "Light Pink", "Light Grey"],
  },
  {
    name: "Pastels: Mint Green, Light Yellow, Peach",
    value: ["Mint Green", "Light Yellow", "Peach"],
  },
  {
    name: "Pastels: Light Orange, Light Purple, Light Blue",
    value: ["Light Orange", "Light Purple", "Light Blue"],
  },
  {
    name: "Brights: Yellow, Orange, Pink",
    value: ["Yellow", "Orange", "Pink"],
  },
  { name: "Brights: Red, Orange, Yellow", value: ["Red", "Orange", "Yellow"] },
  { name: "Brights: Blue, Green, Purple", value: ["Blue", "Green", "Purple"] },
  {
    name: "Brights: Pink, Purple, Magenta",
    value: ["Pink", "Purple", "Magenta"],
  },
  {
    name: "Earthy Neutrals: Tan, Beige, Ivory",
    value: ["Tan", "Beige", "Ivory"],
  },
  {
    name: "Luxury Neutrals: Black, Gold, White",
    value: ["Black", "Gold", "White"],
  },
  {
    name: "Soft Pastels: Powder Blue, Soft Pink, Beige",
    value: ["Powder Blue", "Soft Pink", "Beige"],
  },
  {
    name: "Rich Pastels: Teal, Coral, Mauve",
    value: ["Teal", "Coral", "Mauve"],
  },
  {
    name: "Warm Tones: Rust, Terra Cotta, Mustard Yellow",
    value: ["Rust", "Terra Cotta", "Mustard Yellow"],
  },
  {
    name: "Cool Tones: Slate Blue, Grey, Teal",
    value: ["Slate Blue", "Grey", "Teal"],
  },
  {
    name: "Monochromatic: Charcoal Grey, Silver, Dove Grey",
    value: ["Charcoal Grey", "Silver", "Dove Grey"],
  },
  {
    name: "Monochromatic: Rose, Pink, Blush",
    value: ["Rose", "Pink", "Blush"],
  },
  {
    name: "Complementary: Navy Blue, Burnt Orange",
    value: ["Navy Blue", "Burnt Orange"],
  },
  {
    name: "Complementary: Forest Green, Dusty Rose",
    value: ["Forest Green", "Dusty Rose"],
  },
  {
    name: "Complementary: Purple, Mustard Yellow",
    value: ["Purple", "Mustard Yellow"],
  },
  {
    name: "Analogous: Sunset Tones, Red, Orange, Yellow",
    value: ["Red", "Orange", "Yellow"],
  },
  {
    name: "Analogous: Ocean Tones, Blue, Teal, Green",
    value: ["Blue", "Teal", "Green"],
  },
]

const moodBoardTypes = [
  {
    name: "Interior Design",
    styles, //styles array defined previously
    roomTypes, //roomTypes array defined previously
    colorCombinations, //colorCombinations array defined previously
    url: 'interior-design',
    title: '🏠 AI-Generated Interior Design Mood Boards | Creative Styles & Color Palettes',
    description: 'Explore AI-generated interior design mood boards. Discover various styles, room types, and color combinations to inspire your next home redesign. Step into the future of design.',
    heading: 'Create Your Own Interior Design Mood Boards with AI'
  },
  {
    name: "Fashion",
    styles: ["Boho", "Business Casual", "Street", "Vintage", "Glam"],
    categories: ["Men's", "Women's", "Kids", "Accessories", "Footwear"],
    colorCombinations,
    defaultItems: "shirt, trousers, shoes",
    url: 'fashion',
    title: '👗 AI-Curated Fashion Mood Boards | Men\'s, Women\'s, Kids & Accessories Styles',
    description: 'Discover your style with AI-curated fashion mood boards. From Boho to Glam, explore styles for Men\'s, Women\'s, Kids, and accessories. Reinvent your wardrobe with AI.',
    heading: 'Craft Your Fashion Styles with AI Mood Boards'
  },
  {
    name: "Wedding",
    styles: ["Romantic", "Vintage", "Modern", "Rustic", "Beach", "Boho", "Classic", "Glam", "Garden"],
    categories: ["Ceremony", "Reception", "Floral Arrangement", "Invitations", "Table Decor", "Bridal Outfit", "Groom Outfit"],
    colorCombinations,
    defaultItems: "wedding bouquet, wedding cake, invitation, venue decoration, bride's dress, groom's suit, accessories, photo corner",
    url: 'wedding',
    title: '💍 AI-Crafted Wedding Mood Boards | Ceremony, Reception & Outfit Inspirations',
    description: 'Plan your dream wedding with AI-crafted mood boards. From the Ceremony to the Reception, explore Romantic to Garden styles. Let AI help create your perfect wedding.',
    heading: 'Design Your Dream Wedding with AI Mood Boards'
  },
  {
    name: "Food Branding",
    styles: ["Organic", "Gourmet", "Fast-Casual", "Fine Dining", "Street Food", "Vegan", "Ethnic Cuisine", "Family-Friendly", "Health-Conscious", "Dessert-Focused", "Brunch Spot", "Pet-Friendly", "Food Truck", "Nightlife"],
    categories: ["Packaging", "Menu Design", "Interior Decor", "Ad Concepts", "User Experience"],
    colorCombinations,
    defaultItems: "packaging, menu design, interior decor, ad concepts, user photos",
    url: 'food-branding',
    title: '🍴 AI-Curated Food Branding Mood Boards | Menu, Packaging & Ad Inspirations',
    description: 'Redefine your food brand with AI-curated mood boards. From packaging to user experience, craft a unique brand identity for your restaurant or food product.',
    heading: 'Reimagine Your Food Brand with AI Mood Boards'
  },
  {
    name: "Food Photography",
    styles: ["Product Shots", "Recipe Steps", "Restaurant Ambiance", "Dish Details", "Ingredients", "Culinary Techniques", "Chef Portraits", "Seasonal Specials", "Behind-the-Scenes", "Holiday Themes", "Cultural Cuisine", "Experimental Gastronomy", "Kids Meals", "Beverage Close-Ups"],
    colorCombinations,
    defaultItems: "main course photos, dessert snaps, appetizer visuals, drink images, kitchen process",
    url: 'food-photography',
    title: '🥙 AI-Curated Food Photography Mood Boards | Main Course, Desserts, Drinks & More',
    description: 'Elevate your food photography with AI-generated mood boards. Capture delicious moments from main courses to desserts, all in the perfect light and setting.',
    heading: 'Revamp Your Food Photography with AI-Generated Mood Boards'
  },
  {
    name: "Restaurant Photography",
    styles: ["Fine Dining", "Casual Dining", "Cafe", "Street Stall", "Bar & Grill", "Farm-to-Table", "Seafood", "Steakhouse", "Bakery", "Catering Events", "Themed Dining", "Live Music Venues", "Waterfront", "Buffet Layout", "Rooftop Settings"],
    categories: ["Interior", "Dishes", "Staff", "Guests", "Cooking Process"],
    colorCombinations,
    defaultItems: "interior shots, dish presentation, staff portraits, guests enjoying, cooking shots",
    url: 'restaurant-photography',
    title: '🍽️ AI-Inspired Restaurant Photography Mood Boards | Interior, Dishes & Guest Shots',
    description: "Elevate your restaurant's image with AI-inspired photography mood boards. Capture the essence of your establishment, from the interior to the dishes.",
    heading: "Capture Your Restaurant's Essence with AI Mood Boards"
  },
  {
    name: "Photography",
    styles: ["Portrait", "Landscape", "Macro", "Black and White", "Street", "Aerial", "Event"],
    categories: ["Nature", "People", "Urban", "Abstract", "Fashion"],
    colorCombinations,
    defaultItems: "landscape, portrait, macro",
    url: 'photography',
    title: '📸 AI-Inspired Photography Mood Boards | Nature, People, Urban & Abstract Categories',
    description: 'Discover AI-inspired photography mood boards. From Portrait to Aerial, explore nature, people, urban, and abstract categories. Unleash your creativity with AI.',
    heading: 'Compose Stunning Photography Themes with AI Mood Boards'
  },
]

const countDownRenderer = ({ seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <div></div>
  } else {
    // Render a countdown
    return <div className="text-pink-800">{seconds} seconds</div>
  }
}

const CountdownTimer = () => {
  const [time, setTime] = useState(Date.now() + 45000)

  useEffect(() => {
    setTime(Date.now() + 45000)
  }, [])

  return <Countdown date={time} renderer={countDownRenderer} />
}

const MoodBoardAI = props => {
  const router = useRouter()
  const { slug } = router.query
  let moodBoardTypeName, roomTypeName, style

  if (slug?.length > 0) {
    const result = getMoodBoardAndRoomTypeFromURL(
      slug[0],
      moodBoardTypes,
      roomTypes,
    )
    moodBoardTypeName = result.moodBoardTypeName
    roomTypeName = result.roomTypeName
    if(result.style) style = result.style
  }
  const [selectedMoodBoardType, setSelectedMoodBoardType] = useState(moodBoardTypeName ? moodBoardTypes.find(type => type.name === moodBoardTypeName) : moodBoardTypes[0])
  const [selectedRoom, setSelectedRoom] = useState(roomTypeName ? roomTypes.find(room => room.name === roomTypeName).name : roomTypes[0].name)
  const [selectedStyle, setSelectedStyle] = useState(style || "")
  const [selectedColor, setSelectedColor] = useState("")
  const [customInput, setCustomInput] = useState(selectedMoodBoardType?.defaultItems || roomTypes[0].defaultItems)
  const [moodBoard, setMoodBoard] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [error, setError] = useState(false)
  const fb = useContext(FirebaseContext)
  const { app, user, createBoard } = fb
  const redirect = useRedirectToProfile()
  const { t } = useTranslation()
  const [loadingPercentage, setLoadingPercentage] = useState("0%")
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [showCustomColorInput, setShowCustomColorInput] = useState(false)
  const [isOwner, setIsOwner] = useState(false)
  const [isBrushActive, setIsBrushActive] = useState(false)
  const [imageUpload, setImageUpload] = useState(null)

  const [isVersionModalOpen, setIsVersionModalOpen] = useState(false);
  const [selectedDesignVersion, setSelectedDesignVersion] = useState(2);

  const [isCustomInputFocused, setIsCustomInputFocused] = useState(false);
  const [aspectRatio, setAspectRatio] = useState(1) // Default to 1:1 aspect ratio

  useEffect(() => {
    if (moodBoard?.imageUrl) {
      const img = new Image()
      img.src = moodBoard.imageUrl
      img.onload = () => {
        setAspectRatio(img.width / img.height)
      }
    }
  }, [moodBoard?.imageUrl])

  const handleVersionSelect = (version) => {
    setSelectedDesignVersion(version);
    setIsVersionModalOpen(false);
  };

  useEffect(() => {
    if(props.setType && selectedMoodBoardType) props.setType(selectedMoodBoardType.name)
    if(props.setRoomType && selectedRoom) props.setRoomType(selectedRoom)
    if(props.setStyle) props.setStyle(selectedStyle)
  }, [selectedMoodBoardType, selectedRoom, selectedStyle])

  useEffect(() => {
    const loadMoodBoard = async () => {
      if (!app || !slug) return

      // Check if the slug starts with "b/"
      if (slug[0] === "b" && slug.length > 1) {
        let documentID = slug[1] // The ID follows after "b/"

        try {
          const aiRef = await aiGenerations()
          const docSnapshot = await aiRef.doc(documentID).get()
          let fetchedData
          
          if (docSnapshot.exists) {
            fetchedData = docSnapshot.data()
          } else {
            //load from seo_url
            const boardDataFromSeoUrl = await aiRef.where("seo_url", "==", slug[1]).get()
            if (boardDataFromSeoUrl.size > 0) {
              fetchedData = boardDataFromSeoUrl.docs[0].data()
              documentID = boardDataFromSeoUrl.docs[0].id
            }
          }
          if(fetchedData){
            console.log(fetchedData, user?.uid, fetchedData.uid)
            setMoodBoard({...fetchedData, imageUrl: fetchedData.image, id: documentID}) 
            if(fetchedData.type) setSelectedMoodBoardType(moodBoardTypes.find(type => type.name === fetchedData.type))
            if(fetchedData.roomType) setSelectedRoom(fetchedData.roomType)
            if(fetchedData.style) setSelectedStyle(fetchedData.style)
            if(fetchedData.colorCombination) setSelectedColor(fetchedData.colorCombination)
            // if(fetchedData.detections) setDetections(fetchedData.detections)
          }
        } catch (err) {
          console.error("Error fetching specific document from Firestore:", err)
        }
      }
    }
    
    loadMoodBoard()
  }, [slug, app])
  
  useEffect(() => {
    if(!user || !moodBoard) return
    if(user.uid === moodBoard.uid) {
      setIsOwner(true)
      if(moodBoard.customInput) setCustomInput(moodBoard.customInput)
    } else {
      setIsOwner(false)
    }
  }, [user, moodBoard])


  const handleChange = (setFunction, value) => {
    if (!userIsLoggedIn) {
      setIsDialogOpen(true)
      return
    }
    setFunction(value)
  }

  const editInNewBoard = async (image, id) => {
    if (typeof window !== "undefined") {
      const createCanvasImgObject = (
        await import("../../src/createCanvasImgObject")
      ).default
      const canvasImgObject = await createCanvasImgObject(image, id)
      const boardId = await createBoard({}, { objects: [canvasImgObject] })
      if (boardId instanceof Error) {
        console.error(boardId)
        return
      } else if (boardId) router.push(`/edit/${boardId}`)
    }
  }
  


  const sleep = ms => new Promise(r => setTimeout(r, ms))
  const generateMoodBoardAi = async params => {
    await import("firebase/functions")
    const generateMoodBoardAi = app
      .functions()
      .httpsCallable("generateMoodBoardAi")
    try {
      const handleResponse = (prediction) => {
        setMoodBoard({...prediction, imageUrl: prediction.image || prediction.imageUrl, isStartBrushing: params.upscaleId ? true : false});
        if (prediction.status === "SUCCESS") {
          setIsLoading(false);
          setLoadingPercentage("0%");
        } else if (prediction.status === "FAILURE") {
          setIsLoading(false);
          setLoadingPercentage("0%");
          setError("Error generating mood board. Please retry.");
        }
      };

      const response = await generateMoodBoardAi(params)

      let prediction = response.data

      if (prediction.status === "SUCCESS" || prediction.status === "FAILURE") {
        handleResponse(prediction)
      } else {
        while (
          prediction.status !== "SUCCESS" &&
          prediction.status !== "FAILURE"
        ) {
          await sleep(1000) // Wait for 1 second before retrying
          response = await generateMoodBoardAi({ predictionId: prediction.id })
          prediction = response.data
          setLoadingPercentage(prediction.percentage)
          handleResponse(prediction)
        }
      }
    } catch (e) {
      console.log(e)
      setError(e)
      setIsLoading(false)
    }
  }

  const startLoading = () => {
    setIsLoading(true)
    setError(false)
    setMoodBoard(null)
  }

  const userIsLoggedIn = user && !user.isAnonymous

  const submitHandler = async () => {
    if (!userIsLoggedIn) {
      redirect()
      return
    }
    startLoading()
    const moodBoardData = {
      type: selectedMoodBoardType?.name,
      roomType: selectedRoom,
      style: selectedStyle,
      colorCombination: selectedColor,
      texture: "",
      customInput,
      designVersion: selectedDesignVersion,
    }

    if (imageUpload != null) {
      moodBoardData.imageUpload = imageUpload;
    }

    generateMoodBoardAi(moodBoardData)
  }

  const onChangeSelectedRoom = (room) => {
    setSelectedRoom(room)
    const selectedRoomType = roomTypes.find(r => r.name === room);
    setCustomInput(selectedRoomType ? selectedRoomType.defaultItems : '');
  }

  const handleFinish = async (selectedImage, imageHistory) => {
    try {
      // Check if the selected image is different from the current imageUrl
      if (selectedImage !== moodBoard.imageUrl) {
        // Convert data URL to blob for upload
        const response = await fetch(selectedImage)
        const blob = await response.blob()

        // Create a storage ref with moodBoard.id and date
        await import("firebase/storage")
        const storageRef = app.storage().ref()
        const fileName = `moodboard-${moodBoard.id}-${new Date().getTime()}`
        const imageRef = storageRef.child(`ai-generator-edits/${fileName}`)

        // Upload the blob
        const snapshot = await imageRef.put(blob)

        // Get the URL of the uploaded image
        const storageUrl = await snapshot.ref.getDownloadURL()

        // Update the mood board state with the new image URL
        setMoodBoard(prevMoodBoard => ({
          ...prevMoodBoard,
          imageUrl: storageUrl,
        }))

        // Update the Firestore document with the new image URL
        const aiRef = await aiGenerations()
        await aiRef.doc(moodBoard.id).update({
          image: storageUrl,
        })
      }

      // Update the history in both state and Firestore
      setMoodBoard(prevMoodBoard => ({
        ...prevMoodBoard,
        history: imageHistory,
      }))
      const aiRef = await aiGenerations()
      await aiRef.doc(moodBoard.id).update({
        imageHistory: imageHistory,
      })
    } catch (error) {
      console.error("Error in handleFinish:", error)
      // Handle any errors here
    }
  }
  

  return ( 
    <div className="flex flex-col gap-4 mb-16">
      <ul className='flex overflow-x-auto lg:justify-center'>
        {moodBoardTypes.map(({name, defaultItems, url}) => {
        const isActive = name === selectedMoodBoardType?.name
          
          return (
          <li key={name}>
            <a
              href={`/ai-generator/${url}`}
              onClick={event => {
                event.preventDefault()
                setSelectedMoodBoardType(moodBoardTypes.find(type => type.name === name))
                setSelectedStyle("")
                setSelectedColor("")
                if(defaultItems) setCustomInput(defaultItems)
              }}
              className={`cursor-pointer whitespace-nowrap inline-block text-sm my-3 mx-1 px-4 py-2 rounded-full hover:outline-none hover:opacity-70 ${ isActive ? "text-pink-800 bg-pink-100 font-bold" : "text-gray-800 bg-gray-200"}` }
            >
              {name}
            </a>
          </li>
        )})}
      </ul>
      {selectedMoodBoardType?.roomTypes && <div className="py-1 px-1 flex overflow-x-auto gap-4">
        {selectedMoodBoardType.roomTypes.map((room, index) => (
          <a
            key={index}
            href={`/ai-generator/${selectedMoodBoardType.url}-${room.url}`}
            className={`p-4 ${
              selectedRoom === room.name ? "ring ring-pink-500" : ""
            } text-gray-700 hover:text-pink-700 rounded-xl shadow cursor-pointer`}
            onClick={event => {event.preventDefault(); onChangeSelectedRoom(room.name)}}
          >
            <div className="">{room.icon}</div>
            <p className="mt-2 text-center whitespace-nowrap">{room.name}</p>
          </a>
        ))}
      </div>}
      <div className={`flex flex-col md:flex-row gap-4 mb-8  ${isCustomInputFocused || imageUpload ? 'sm:grid sm:grid-cols-2 sm:auto-rows-auto sm:max-w-screen-2xl sm:mx-auto' : ''}`}>
        {selectedMoodBoardType?.styles && <select
          className=" block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-0 focus:ring focus:bg-white focus:ring-pink-500 hover:text-pink-700"
          value={selectedStyle}
          onChange={e => setSelectedStyle(e.target.value)}
        >
          <option value="">Styles</option>
          {selectedMoodBoardType.styles.map((style, index) => (
            <option key={index} value={style}>
              {style}
            </option>
          ))}
        </select>}
        {showCustomColorInput ? (
              <div className="w-full flex items-center gap-2 hover:text-pink-700">
                <input
                  type="text"
                  value={selectedColor}
                  placeholder="Color"
                  onChange={e => handleChange(setSelectedColor,e.target.value)}
                  className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:ring focus:bg-white focus:ring-pink-500 hover:text-pink-700"
                />
                <EditArrowBack className="h-6 w-6 cursor-pointer" onClick={() => setShowCustomColorInput(false)} />
              </div>
            ) : (
            <select
              className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:ring focus:bg-white focus:ring-pink-500 hover:text-pink-700"
              value={selectedColor}
              onChange={e => {
                if (e.target.value === "custom") {
                  setShowCustomColorInput(true);
                } else {
                  handleChange(setSelectedColor, e.target.value);
                }
              }}
            >
              <option value="">Colors</option>
              {colorCombinations.map((color, index) => (
                <option key={index} value={color.value}>
                  {color.name}
                </option>
              ))}
              <option value="custom">Custom color</option>
            </select>
          )}


        <textarea
          value={customInput}
          onFocus={() => setIsCustomInputFocused(true)}
          onChange={e => handleChange(setCustomInput, e.target.value)}
          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:border-0 focus:ring focus:bg-white focus:ring-pink-500 hover:text-pink-700 sm:col-span-2" 
          rows={isCustomInputFocused ? "4" : "1"}
        />
        {/* <div className="w-full col-span-2">
          <ImageUpload onImageAdded={setImageUpload} handleChange={handleChange} />
        </div> */}

          <div className="flex w-full gap-2">
            <button
              className="flex-1 bg-pink-800 hover:bg-pink-700 disabled:bg-gray-700 text-white font-bold py-2 px-4 rounded"
              onClick={submitHandler}
              disabled={isLoading}
            >
              Submit
            </button>
            {selectedMoodBoardType?.name === "Interior Design" && <button
              className="bg-white border border-gray-200 text-pink-700 py-2 px-4 rounded"
              onClick={() => setIsVersionModalOpen(true)}
            >
              Select Version
            </button>}
          </div>
          <VersionSelectionModal
            isOpen={isVersionModalOpen}
            onClose={() => setIsVersionModalOpen(false)}
            onSelect={handleVersionSelect}
            selectedVersionId={selectedDesignVersion}
          />
      </div>
      <div>
        {isLoading && <CountdownTimer renderer={countDownRenderer} />}
        {error?.code == "resource-exhausted" ? (
          <div className="flex flex-col md:flex-row justify-center items-center gap-3">
            Please upgrade your plan to use the AI Generator. 🫣
            <Button href="/pricing" highlight>
              {t("plans.upgrade")}
            </Button>
          </div>
        ) : (
          error && <>Oops, the mood board generation failed.</>
        )}
      </div>
      {(isLoading || moodBoard?.imageUrl || moodBoard?.isGrid) && <div className={`relative mx-auto  w-full md:w-4/5 lg:max-w-2xl mb-12 ${isLoading ? "aspect-1" : ""}`}>
        {isLoading && (
            <div className="absolute z-10 w-full h-full bg-white bg-opacity-50"><Loading percentage={loadingPercentage} /></div>
        )} 
        {moodBoard?.imageUrl ? !moodBoard.isGrid ? (
          <div className="relative">
            <BrushableImage src={moodBoard.imageUrl} isOwner={isOwner} className="object-cover rounded-lg mb-4" onFinish={handleFinish} isStartBrushing={moodBoard.isStartBrushing} isLoading={isLoading} setIsBrushActiveParent={setIsBrushActive}/>
            {!isBrushActive && isOwner && (
              <>
                <LoadingButton onClick={async () => await editInNewBoard(moodBoard.imageUrl, moodBoard.id)} highlight>
                  Add to designer
                </LoadingButton>
                <Button href={`https://lens.google.com/uploadbyurl?url=${encodeURIComponent(moodBoard.imageUrl)}`} target="_blank" rel="noopener noreferrer" highlight>Find similar products</Button>
              </>
            )}
          </div>
        ) : (
        <div className  ="grid grid-cols-2 gap-4 w-full">
            {Array.from({ length: 4 }).map((_, index) => {
              const positions = [
                { backgroundPosition: 'top left' },
                { backgroundPosition: 'top right' },
                { backgroundPosition: 'bottom left' },
                { backgroundPosition: 'bottom right' },
              ];

          return (
            <div key={index} className="relative w-full" style={{ paddingBottom: `${100 / aspectRatio}%` }}>
              <div className="absolute inset-0">
                <a
                  style={{
                    backgroundImage: `url(${moodBoard.imageUrl})`,
                    backgroundSize: "200%",
                    backgroundPosition: positions[index].backgroundPosition,
                  }}
                  className="absolute inset-0 group object-cover rounded-xl w-full h-full hover:ring-4 hover:ring-pink-800 hover:shadow-lg"
                >
                  <div className="hidden group-hover:flex justify-center gap-2 absolute left-0 bottom-0 right-0 p-3 bg-white/20 backdrop-blur rounded-b-xl sm:text-sm text-xs">
                    <button
                      onClick={() => {
                        startLoading()
                        generateMoodBoardAi({
                          upscaleId: moodBoard.id,
                          upscaleIndex: index + 1,
                          type: selectedMoodBoardType?.name,
                          roomType: selectedRoom,
                          style: selectedStyle,
                          colorCombination: selectedColor,
                          texture: "",
                          customInput,
                          designVersion: selectedDesignVersion,
                        })
                      }}
                      className="bg-pink-800 text-white p-2 rounded shadow hover:bg-pink-700"
                    >
                      Choose
                    </button>
                    <button
                      onClick={() => {
                        startLoading()
                        generateMoodBoardAi({
                          similiarId: moodBoard.id,
                          similiarIndex: index + 1,
                          type: selectedMoodBoardType?.name,
                          roomType: selectedRoom,
                          style: selectedStyle,
                          colorCombination: selectedColor,
                          texture: "",
                          customInput,
                          designVersion: selectedDesignVersion,
                        })
                      }}
                      className="bg-white p-2 rounded shadow text-black hover:outline hover:outline-pink-400"
                    >
                      Create similar
                    </button>
                  </div>
                </a>
              </div>
            </div>
          )
        })}
      </div>
        ) : (
          <div className="bg-gray-50 w-full h-full rounded-lg"></div>
        )}
      </div>}
      <Transition.Root show={isDialogOpen} as={Fragment}>
        <Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={isDialogOpen} onClose={setIsDialogOpen}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">Subscription Required</Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">Please subscribe first to use the Mood Board AI Generator.</p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button onClick={() => setIsDialogOpen(false)} className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-600 text-base font-medium text-white hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 sm:ml-3 sm:w-auto sm:text-sm">
                    Close
                  </button>
                  <button onClick={() => window.location.href='/pricing'} className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-pink-600 text-base font-medium text-white hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 sm:ml-3 sm:w-auto sm:text-sm">
                    Go to Pricing
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

    </div>
  )
}

const Index = props => {
  const { t } = useTranslation()
  const boardData = props.boardData ? JSON.parse(props.boardData) : null
  let boardDataTitle
  if(boardData) {
    boardDataTitle = boardData.type + " Mood Board"
    if(boardData.roomType && boardData.type == "Interior Design") boardDataTitle += ", " + boardData.roomType
    if(boardData.style) boardDataTitle += ": " + boardData.style + " style"
    if(boardData.colorCombination) boardDataTitle += ", colors of " + boardData.colorCombination
  }
  let title = !props.isStartpage ? props.moodBoardTypeName ? moodBoardTypes.find(type => type.name === props.moodBoardTypeName).title : t('ai-generator.title') : t('ai-generator.title')
  let description = props.moodBoardTypeName ? moodBoardTypes.find(type => type.name === props.moodBoardTypeName).description :  t('hero.ai-description')
  let heading = !props.isStartpage ? props.moodBoardTypeName ? moodBoardTypes.find(type => type.name === props.moodBoardTypeName).heading : t('ai-generator.title') : t('ai-generator.title')
  if(props.roomTypeName && props.roomTypeName !== "") {
    title = roomTypes.find(type => type.name === props.roomTypeName).title
    description = roomTypes.find(type => type.name === props.roomTypeName).description
    heading = roomTypes.find(type => type.name === props.roomTypeName).heading
  }

  if(boardData) {
    if(boardData.seo_description) description = boardData.seo_description
    if(boardData.seo_title) {
      boardDataTitle = boardData.seo_title
      heading = boardData.seo_title
    }
  }
  const structuredData = {
    "@context":"https://schema.org",
    "@graph":[
        {
            "@type":"Organization",
            "@id":"https://moodboardai.com/ai-generator/#organization",
            "name":"Moodboardly",
            "url":"https://moodboardai.com/ai-generator",
            "sameAs":[
                "https://www.instagram.com/moodboardlyai/"
            ],
            "email":"support@moodboardai.com",
            "logo":{
                "@type":"ImageObject",
                "@id":"https://moodboardai.com/ai-generator/#logo",
                "url":"https://moodboardai.com/logo.png",
                "contentUrl":"https://moodboardai.com/logo.png",
                "caption":"Moodboardly",
                "inLanguage":"en-US"
            },
            "contactPoint":[
                {
                    "@type":"ContactPoint",
                    "telephone":"+65 0000 0000",
                    "contactType":"customer support"
                }
            ]
        },
        {
            "@type":"WebSite",
            "@id":"https://moodboardai.com/ai-generator/#website",
            "url":"https://moodboardai.com/ai-generator",
            "name":"Moodboardly",
            "publisher":{
                "@id":"https://moodboardai.com/ai-generator/#organization"
            },
            "inLanguage":"en-US"
        },
        {
            "@type":"ImageObject",
            "@id":"https://moodboardai.com/ai-generator/#primaryimage",
            "url":"https://moodboardai.com/assets/ai-generator.jpg",
            "caption":"AI generator for mood boards",
            "inLanguage":"en-US"
        },
        {
            "@type":"WebPage",
            "@id":"https://moodboardai.com/ai-generator/#webpage",
            "url":"https://moodboardai.com/ai-generator",
            "name":"🎨 Moodboardly AI Generator: Create Stunning Mood Boards with AI",
            "datePublished":"2023-02-11T00:00:00+00:00",
            "dateModified":"2023-07-30T02:51:55+00:00",
            "isPartOf":{
                "@id":"https://moodboardai.com/ai-generator/#website"
            },
            "primaryImageOfPage":{
                "@id":"https://moodboardai.com/ai-generator/#primaryimage"
            },
            "inLanguage":"en-US"
        },
        {
            "@type":"Product",
            "brand":{
                "@type":"Brand",
                "name":"Moodboardly"
            },
            "name":"Moodboardly AI Generator",
            "description":"Our AI generator tool helps you create stunning and professional-looking mood boards in less than 60 seconds. Perfect for interior design projects, brand development, website design, and more.",
            "sku":"ai-generator",
            "category":"Design Tools",
            "mainEntityOfPage":{
                "@id":"https://moodboardai.com/ai-generator/#webpage"
            },
            "aggregateRating":{
                "@type":"AggregateRating",
                "ratingValue":"5.00",
                "bestRating":"5",
                "ratingCount":"38",
                "reviewCount":"38"
            },
            "review": [
              {
                  "@type": "Review",
                  "@id": "https://moodboardai.com/ai-generator/#li-comment-1505901",
                  "description": "The AI generator from Moodboardly has revolutionized my design process. It's so fast and the results are always impressive!",
                  "datePublished": "2023-07-22T16:19:56",
                  "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "5"
                  },
                  "author": {
                      "@type": "Person",
                      "name": "Sarah, Graphic Designer"
                  }
              },
              {
                  "@type": "Review",
                  "@id": "https://moodboardai.com/ai-generator/#li-comment-1505902",
                  "description": "Using Moodboardly's AI generator saved me so much time when planning my latest project.",
                  "datePublished": "2023-07-21T11:35:42",
                  "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "5"
                  },
                  "author": {
                      "@type": "Person",
                      "name": "Michael, Interior Designer"
                  }
              },
              {
                  "@type": "Review",
                  "@id": "https://moodboardai.com/ai-generator/#li-comment-1505903",
                  "description": "I absolutely love the AI generator! It's so intuitive and produces high quality results.",
                  "datePublished": "2023-07-20T09:12:37",
                  "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "5"
                  },
                  "author": {
                      "@type": "Person",
                      "name": "Emily, Fashion Designer"
                  }
              },
              {
                  "@type": "Review",
                  "@id": "https://moodboardai.com/ai-generator/#li-comment-1505904",
                  "description": "Moodboardly's AI generator is a game changer. It's so much easier to visualize my ideas now.",
                  "datePublished": "2023-07-18T14:23:11",
                  "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "5"
                  },
                  "author": {
                      "@type": "Person",
                      "name": "John, Art Director"
                  }
              },
              {
                  "@type": "Review",
                  "@id": "https://moodboardai.com/ai-generator/#li-comment-1505905",
                  "description": "Impressive tool! The AI generator is fast, efficient, and always gives me great ideas.",
                  "datePublished": "2023-07-17T10:06:22",
                  "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "5"
                  },
                  "author": {
                      "@type": "Person",
                      "name": "Jessica, Brand Strategist"
                  }
              },
              {
                  "@type": "Review",
                  "@id": "https://moodboardai.com/ai-generator/#li-comment-1505906",
                  "description": "Moodboardly's AI generator has significantly improved my workflow. Highly recommended!",
                  "datePublished": "2023-07-16T15:29:33",
                  "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "5"
                  },
                  "author": {
                      "@type": "Person",
                      "name": "Daniel, Product Designer"
                  }
              },
              {
                  "@type": "Review",
                  "@id": "https://moodboardai.com/ai-generator/#li-comment-1505907",
                  "description": "The AI generator is a must-have tool for anyone needing to create mood boards. Super user-friendly and efficient!",
                  "datePublished": "2023-07-15T11:11:12",
                  "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "5"
                  },
                  "author": {
                      "@type": "Person",
                      "name": "Sophie, Creative Director"
                  }
              },
              {
                  "@type": "Review",
                  "@id": "https://moodboardai.com/ai-generator/#li-comment-1505908",
                  "description": "As an architect, I find Moodboardly's AI generator incredibly useful for bringing my concepts to life. I can't imagine my design process without it now.",
                  "datePublished": "2023-07-14T09:57:42",
                  "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "5"
                  },
                  "author": {
                      "@type": "Person",
                      "name": "Oliver, Architect"
                  }
              }
          ]
          
        }
    ]
}

 const images = props.images ? JSON.parse(props.images) : []
 const [type, setType] = useState(props.moodBoardTypeName)
 const [roomType, setRoomType] = useState(props.roomTypeName)
 const [style, setStyle] = useState(props.style)


  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(structuredData),
          }}
        />
      </Head>
      <MetaTags
        title={boardDataTitle ? boardDataTitle : title}
        description={description}
        image={boardData ? boardData.image : images.length > 0 ? images[0].image :  "/assets/ai-generator.jpg"}
        hasTranslation={false}
      />
      <LayoutFirebase>
        <div className="container px-5 pt-12 mx-auto">
          <div className="flex flex-col text-center w-full mb-20">
            <h1 className="font-serif sm:text-4xl text-3xl font-medium mb-6 text-gray-900 text-left sm:text-center">
              {heading}
            </h1>
            {!props.isStartpage && <p className="lg:w-2/3 mx-auto leading-relaxed text-base text-left sm:text-center mb-6 max-w-3xl">{description}</p>}
            <p className="sm:text-xl text-base lg:w-2/3 mx-auto leading-relaxed text-gray-500"></p>
            <MoodBoardAI setType={setType} setRoomType={setRoomType} setStyle={setStyle} />
            <div className="md:mt-10 border-t border-gray-200">
              <h2 className="mt-12 py-3 text-xl text-center text-gray-700">
              Latest Moodboards
            </h2>
            <UserImages images={images} type={type} roomType={roomType} style={style} />
            </div>
          </div>
        </div>
        <div className="container mx-auto px-4 text-left pb-20">
              <div className="items-center flex flex-wrap-reverse md:flex-wrap">
                <div className="w-full md:w-5/12 ml-auto mr-auto px-4 pt-8 md:pt-0">
                <video
                    className="rounded-lg shadow-xl"
                    autoPlay
                    loop
                    muted
                    playsInline
                  >
                    <source src="/assets/ai/Creating-a-mood-board-with-MoodBoardly-AI.mp4" type="video/mp4" />
                  </video>
                </div>
                <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                  <div className="md:pr-12">
                    <h3 className="font-serif text-3xl font-semibold">One-click {props.moodBoardTypeName || ""} mood board creation</h3>
                    <p className="mt-4 text-lg leading-relaxed text-gray-600">
                    Craft unique mood boards with just one click, thanks to our AI-powered generator.
                    </p>
                    <ul className="list-none mt-6">
                      <li className="py-2">
                        <div className="flex items-center">
                          <div>
                            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                            </span>
                          </div>
                          <div>
                            <h4 className="text-gray-600">
                            Generate stunning mood boards with MoodBoardly AI for {props.moodBoardTypeName || ""} branding, and beyond.
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="flex items-center">
                          <div>
                            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                              {/* <i className="fab fa-html5"></i> */}
                            </span>
                          </div>
                          <div>
                            <h4 className="text-gray-600">Explore and create mood boards for diverse styles – Contemporary, Modern, Traditional, and more!
Add your AI-generated mood board to our designer</h4>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>


            <section className="relative py-20 pb-36 text-left">
                  <div className="container mx-auto px-4">
                    <div className="flex flex-wrap-reverse md:flex-wrap md:flex-row-reverse">
                      <div className="w-full md:w-5/12 ml-auto mr-auto px-4 pt-8 md:pt-0">
                        <video className="rounded-lg shadow-xl" autoPlay loop muted playsInline>
                          <source
                            src="/assets/ai/add-ai-mood-board-to-designer.mp4"
                            type="video/mp4"
                          />
                        </video>
                      </div>
                      <div className="w-full md:w-5/12 ml-auto mr-auto px-4 flex items-center">
                        <div className="md:pr-12">
                          <h3 className="font-serif text-3xl font-semibold">
                            Add your AI-generated mood board to our designer
                          </h3>
                          <p className="mt-4 mb-8 text-lg leading-relaxed text-gray-600">
                            Let AI-powered mood boards inspire your creative workflow!
                          </p>
                          <ul className="list-none mt-6">
                            <li className="py-2">
                              <div className="flex items-center">
                                <div>
                                  <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                                  </span>
                                </div>
                                <div>
                                  <h4 className="text-gray-600">
                                  Give your AI generated mood board to the designer, and let our intuitive mood board designer help you with your creative project.
                                  </h4>
                                </div>
                              </div>
                            </li>
                            
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>


              <div className="container mx-auto px-4 text-left pb-20">
              <div className="items-center flex flex-wrap-reverse md:flex-wrap">
                <div className="w-full md:w-5/12 ml-auto mr-auto px-4 pt-8 md:pt-0">
                <video
                    className="rounded-lg shadow-xl"
                    autoPlay
                    loop
                    muted
                    playsInline
                  >
                    <source src="/assets/ai/find-similar-products.mp4" type="video/mp4" />
                  </video>
                </div>
                <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                  <div className="md:pr-12">
                    <h3 className="font-serif text-3xl font-semibold">Let the magic work, and find products easily</h3>
                    <p className="mt-4 text-lg leading-relaxed text-gray-600">
                    Find real products, similar to the featured ones on your AI created mood board.
                    </p>
                    <ul className="list-none mt-6">
                            <li className="py-2">
                              <div className="flex items-center">
                                <div>
                                  <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                                  </span>
                                </div>
                                <div>
                                  <h4 className="text-gray-600">
                                  Finding real products for your {props.moodBoardTypeName || ""} project was never easier!
                                  </h4>
                                </div>
                              </div>
                            </li>
                            <li className="py-2">
                              <div className="flex items-center">
                                <div>
                                  <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                                    {/* <i className="fab fa-html5"></i> */}
                                  </span>
                                </div>
                                <div>
                                  <h4 className="text-gray-600">You can discover products for your {props.moodBoardTypeName || ""} project, import them into the designer, and include them in your shopping list.</h4>
                                </div>
                              </div>
                            </li>
                          </ul>
                  </div>
                </div>
              </div>
            </div>
      </LayoutFirebase>
    </>
  )
}

export default Index



function getMoodBoardAndRoomTypeFromURL(slug, moodBoardTypes, roomTypes) {
  // check if starts with any of the moodBoardTypes
  const moodBoardType = moodBoardTypes.find(moodBoardType =>
    slug.startsWith(moodBoardType.url),
  )

  if (!moodBoardType) {
    return {}
  }
  else if (slug === moodBoardType.url) {
    return {
      moodBoardTypeName: moodBoardType.name,
    }
  }
  const slugWithoutMoodBoardType = slug.replace(`${moodBoardType.url}-`, "")
  // check if starts with any of the roomTypes
  const roomType = roomTypes.find(roomType =>
    slugWithoutMoodBoardType.startsWith(roomType.url),
  )

  return {
    moodBoardTypeName: moodBoardType.name,
    roomTypeName: roomType.name,
  }
}

export const getStaticProps = async ({ locale, params }) => {
  const aiRef = await aiGenerations()

  const { slug } = params
  const isStartpage = slug === undefined || slug.length === 0
  let moodBoardTypeName = "Interior Design"
  let roomType = ""
  let style = ""
  let boardData = null

  if (slug?.length === 2 && slug[0] === 'b') {
    // Handle case where slug is in the format '/b/BOARDID'
    const boardId = slug[1];
    const boardDoc = await aiRef.doc(boardId).get();
    if (boardDoc.exists) {
      boardData = { ...boardDoc.data(), id: boardDoc.id };
    } else {
      const boardDataFromSeoUrl = await aiRef.where("seo_url", "==", slug[1]).get()
      if (boardDataFromSeoUrl.size > 0) {
        boardData = { ...boardDataFromSeoUrl.docs[0].data(), id: boardDataFromSeoUrl.docs[0].id };
      }
    }
  }  
  
  if (slug?.length > 0) {
    const result = getMoodBoardAndRoomTypeFromURL(
      slug[0],
      moodBoardTypes,
      roomTypes,
    )
    if (result.moodBoardTypeName !== undefined)
      moodBoardTypeName = result.moodBoardTypeName
    if (result.roomTypeName !== undefined) roomType = result.roomTypeName
    if (result.style !== undefined) style = result.style

  }
  let aiImageRef = aiRef
    .where("status", "==", "SUCCESS")
    .where("isGrid", "==", false)
    .where("type", "==", moodBoardTypeName)
    .orderBy("created", "desc")
    .limit(12)
  if (roomType) {
    aiImageRef = aiImageRef.where("roomType", "==", roomType)
  }

  if (style) {
    aiImageRef = aiImageRef.where("style", "==", style)
  }
  const aiImagesCollection = await aiImageRef.get()
  const images = aiImagesCollection.docs.map(doc => ({
    ...doc.data(),
    id: doc.id,
  }))
  return {
    props: {
      ...(await serverSideTranslations(locale)),
      images: JSON.stringify(images),
      boardData: JSON.stringify(boardData),
      moodBoardTypeName,
      roomTypeName: roomType,
      style,
      isStartpage,
    },
    revalidate: 86400, // daily (24 hours)
  }
}

export async function getStaticPaths() {
  const paths = moodBoardTypes.flatMap(moodBoard => {
    const cleanUrl = (url) => url.toLowerCase().replace(/[\s-]+/g, '-');
  
    const moodBoardPath = [{ params: { slug: [cleanUrl(moodBoard.url)] } }];
  
    const roomTypePaths = moodBoard.roomTypes?.map(roomType => ({
      params: { slug: [`${cleanUrl(moodBoard.url)}-${cleanUrl(roomType.url)}`] },
    })) || [];
  
    // Concatenate the mood board path with any room type paths
    return moodBoardPath.concat(roomTypePaths);
  });

  const pathsBoards = []

  const aiRef = await aiGenerations()
  const latestBoardsRef = aiRef
    .where("type", "!=", "Interior Design AI")
    .where("status", "==", "SUCCESS")
    .where("isGrid", "==", false)
    .limit(parseInt(process.env.STATIC_LIMIT))
  const latestBoards = await latestBoardsRef.get()

  latestBoards.forEach(boardDoc => {
    const boardData = boardDoc.data()
    const boardId = boardDoc.id
    const slugValue = boardData.seo_url || boardId
    pathsBoards.push({ params: { slug: ["b", slugValue] } })
  })

  return {
    paths: [...paths, ...pathsBoards],
    fallback: 'blocking',
  }
}




export const LinkListComponent = () => {
  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 p-4">
      {moodBoardTypes.flatMap(moodBoard =>
        [
          <div key={moodBoard.name} className="font-bold text-lg capitalize">
            <a href={`/ai-generator/${moodBoard.url}`} title={moodBoard.title}>
              {moodBoard.name}
            </a>
          </div>,
        ].concat(
          (moodBoard.roomTypes ? moodBoard.roomTypes.slice(0, 10) : []).map(
            roomType => (
              <div key={roomType.name} className="font-bold text-lg capitalize">
                <a
                  href={`/ai-generator/${moodBoard.url}-${roomType.url}`}
                  title={roomType.title}
                >
                  {roomType.name}
                </a>
              </div>
            ),
          ),
        ),
      )}
    </div>
  )
}




