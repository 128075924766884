import React, { useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { Fragment } from "react"
import { StyledModal } from "./StyledModal" // Assuming StyledModal is a custom component

const designVersions = [
  {
    id: 2,
    name: "Newer Model",
    image: "/assets/styles/moodboard-v6.webp",
    description: "Experience the latest advancements with our Newer Model, featuring enhanced text quality and improved accuracy.",
  },
  {
    id: 1,
    name: "Classic Model",
    image: "/assets/styles/moodboard-v4.webp",
    description: "Revisit the timeless Classic Model, perfect for those who appreciate the original design. Note: Texts may require cleaning with our tool.",
  },
]

const VersionSelectionModal = ({ isOpen, onClose, onSelect, selectedVersionId }) => {
  return (
    <StyledModal isOpen={isOpen} onClose={onClose}>
      <div className="overflow-auto p-4 pt-8 sm:px-16">
        <h1 className="text-2xl text-center mt-8 mb-16">
          Select Design Version
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {designVersions.map((version, index) => (
            <div
              key={index}
              className={`relative border rounded-lg cursor-pointer hover:shadow-lg ${selectedVersionId === version.id ? 'ring-4 ring-pink-700' : ''}`}
              onClick={() => onSelect(version.id)}
            >
              <div className="aspect-w-1 aspect-h-1">
                <img
                  src={version.image}
                  alt={version.name}
                  className="w-full h-full object-cover rounded-lg mb-2"
                />
                <div className="absolute inset-0 flex flex-col justify-end rounded-b-lg">
                  <div className="bg-white bg-opacity-80 backdrop-blur-sm p-4 rounded-b-lg">
                    <h3 className="text-lg font-semibold text-black">
                      {version.name}
                    </h3>
                    <p className="text-sm text-gray-800">
                      {version.description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </StyledModal>
  )
}

export default VersionSelectionModal
